"use client";
import Button from "@/components/Button";

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  console.log("error", error);
  return (
    <div className={"my-4 flex flex-col items-center gap-4"}>
      <div className="w-full bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
        <span className="block sm:inline">Something went wrong...!</span>
      </div>
      <Button onClick={() => reset()} className={"w-[120px]"}>
        Refresh
      </Button>
    </div>
  );
}
